


















import SharedDashboardGetNotification from "@/components/Shared/Dashboard/GetNotification.vue";
import SharedDashboardProfileAlert from "@/components/Shared/Dashboard/ProfileAlert.vue";
import { toDisplayName } from "@/helpers/Formatter";
// import { getPermission } from "@/services/Push";
import { Component, Vue } from "vue-property-decorator";
import AdminDashboard from "./Admin/Dashboard.vue";
import CompanyDashboard from "./Company/Dashboard.vue";
import ProposerDashboard from "./Proposer/Dashboard.vue";

@Component({
    components: {
        AdminDashboard,
        CompanyDashboard,
        ProposerDashboard,
        SharedDashboardProfileAlert,
        SharedDashboardGetNotification,
    },
    metaInfo() {
        const me = this.$store.state.users.me;
        const page = "Dashboard - ";
        const title = page + toDisplayName(me);

        return {
            title: title,
        };
    },
})
export default class AppDashboard extends Vue {
    get meRole() {
        return this.$store.getters["users/meRole"];
    }

    getPermission() {
        // alert("Get Permission");
        // getPermission();
    }
}
